import React from 'react';
import { Feature } from '../../components';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section__margin' id='wgpt3'>
      <div className='gpt3__whatgpt3-feature'>
        <Feature title="What is PisKu NFT" text="NFTs on Solana are unique digital assets like art, music, and collectibles represented on Solana's high-performance blockchain. They offer secure ownership records and enable buying, selling, and trading of digital creations in a transparent and efficient manner." />
      </div>
      <div className='gpt3__whatgpt3-heading'>
        <h1 className='gradient__text'>Future Benefits for Holders</h1>
        <p>Discord Holder only</p>
      </div>
      <div className='gpt3__whatgpt3-container'>
        <Feature title="Discord Holders Only" text="Holders of our NFTs will gain access to our exclusive Discord community reserved for holders, where they can engage with fellow collectors, unlock unique perks and  receive early announcements about the latest updates ahead of non-holders." />,
        <Feature title="Holders Staking Sistem" text="Prepare to maximize your rewards with our upcoming staking system exclusively for holders. Stake your NFTs to earn various tokens on the Solana network, providing you with exciting opportunities for additional value and benefits. This will be available after the sold-out." />,
        <Feature title="Exclusive Content" text=" With our NFT's you can gain access to exclusive content, including behind-the-scenes insights, special artworks, and more, as a token of gratitude for your support." />,
      </div>
    </div>
  )
}

export default WhatGPT3