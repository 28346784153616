import React, { useState } from 'react';
import './cta.css';

const CTA = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false); 

  const handleButtonClick = () => {
    setIsButtonClicked(true); 

    // Imposta lo stato a false dopo 500 millisecondi (mezzo secondo)
    setTimeout(() => {
      setIsButtonClicked(false);
    }, 200);
  };

  return (
    <div className='gpt3__cta'>
        <div className='gpt3__cta-content'>
          <p>Request Early Access to Get Started</p>
          <h3>Click here to Join on our Discord Community</h3>
        </div>
        <div className='gpt3__cta-btn'>
          <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
            <button className={isButtonClicked ? 'button-click-animation clicked' : 'button-click-animation'} type='button' onClick={handleButtonClick}>Get Started</button>
          </a>
        </div>
    </div>
  )
}

export default CTA;
