import React from 'react';
import './roadmap.css';
import roadmapImage from '../../assets/roadmap.png'; // Importa l'immagine dalla cartella delle risorse

const Roadmap = () => {
  const openImageViewer = () => {
    window.open(`${window.location.origin}/roadmap.html`, '_blank'); // Apre l'HTML image viewer in una nuova finestra
};

  return (
    <div className="gpt3__roadmap section__padding" id='roadmap'>
      <div className='gpt3__roadmap-content'>
        <h2 className="gradient__text">PisKu Roadmap v1</h2>
      </div>

      <div className='gpt3__roadmap-img' onClick={openImageViewer}>
        <img src={roadmapImage} alt='roadmap'></img>
      </div>
    </div>
  );
}

export default Roadmap;

