import React from 'react';
import { solana, magiceden, tensor, twitter, shopify } from './imports';
import './brand.css';

const Brand = () => {
  return (
    <div className='gpt3__brand section__padding'>
        <div>
          <img src= {solana} alt='solana' />
        </div>
        <div>
          <img src= {magiceden} alt='magiceden' />
        </div>
        <div>
          <img src= {tensor} alt='tensor' />
        </div>
        <div>
          <img src= {twitter} alt='twitter' />
        </div>
        <div>
          <img src= {shopify} alt='shopify' />
        </div>
    </div>
  )
}

export default Brand