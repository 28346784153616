import React from 'react';

import possibilityImage from '../../assets/possibility.png'
import './possibility.css';

const Possibility = () => {
  return (
    <div className='gpt3__possibility section__padding' id='possibility'>
        <div className='gpt3__possibility-image'>
          <img src={possibilityImage} alt='possibility' />
        </div>
        <div className='gpt3__possibility-content'>
          <h4>Discord Early Access Before Mint Starts</h4>
          <h1 className='gradient__text'>Join on Discord before the Mint starts!</h1>
          <p>Join our Discord now and secure your spot in our exclusive holder rooms! After minting, you'll be able to verify your NFTs and gain access to our holder-exclusive spaces. Don't miss out on the opportunity to be part of our community from the get-go and get ready to experience something truly unique in the world of NFTs!</p>
          <h4>Official PisKu Discord </h4>
        </div>
    </div>
  );
}

export default Possibility;