import React from 'react';
import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => {
  return (
    <div className='gpt3__blog section__padding' id='blog'>
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>PisKu News</h1>
      </div>
      <div className='gpt3__blog-container'>
        <div className='gpt3__blog-container_groupA'>
          <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
            <Article imgURL={blog01} date='Online' title='Official Discord Community & NFT Holders Room' />
          </a>
        </div>
        <div className='gpt3__blog-container_groupB'>
          <a href='https://launchmynft.io' target='_blank' rel='noopener noreferrer'>
            <Article imgURL={blog02} date='TBA' title='Official Mint Page On LaunchmyNFT' />
          </a>
          <a href='https://piskunft.com' target='_blank' rel='noopener noreferrer'>
            <Article imgURL={blog03} date='After SOLD-OUT' title='Official NFTs Staking System for Holders Only' />
          </a>
          <a href='https://twitter.com/piskunft' target='_blank' rel='noopener noreferrer'>
            <Article imgURL={blog04} date='Follow Pisku on X' title='Official X Page News' />
          </a>
          <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
            <Article imgURL={blog05} date='Founders/Developers' title='isDade & xoxer from Italy with some spaghetti and a bunch of pizzas' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
