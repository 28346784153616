import React from 'react';
import './footer.css';
import gpt3logo from '../../assets/logo.svg';

const Footer = () => {
  const handleCopyWallet = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => {
        console.log('Indirizzo del portafoglio copiato con successo:', walletAddress);
        alert('Indirizzo del portafoglio copiato con successo!');
      })
      .catch(err => {
        console.error('Si è verificato un errore durante la copia dell\'indirizzo del portafoglio:', err);
        alert('Si è verificato un errore durante la copia dell\'indirizzo del portafoglio.');
      });
  };

  return (
    <div className='gpt3__footer section__padding'>
      <div className='gpt3__footer-heading'>
         <h1 className='gradient__text'>Our Community is Waiting You!</h1>
      </div>

      <div className='gpt3__footer-btn'>
      <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
        <p>PisKu Community</p>
      </a>
      </div>

      <div className='gpt3__footer-links'>
        <div className='gpt3__footer-links_logo'>
          <img src={gpt3logo} alt='logo' />
          <p>PisKu Community</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Social</h4>
          <a href='https://twitter.com/piskunft' target='_blank' rel='noopener noreferrer'>
          <p>X/Twitter</p>
          </a>
          <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
          <p>Discord</p>
          </a>
          <a href='https://www.instagram.com/piskulinci?igsh=MWVqM3VjNDR6MHdxcw==' target='_blank' rel='noopener noreferrer'>
          <p>Instagram</p>
          </a>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Company</h4>
          <p>Extence</p>
          <p>PiskuNFT</p>
          <p>info@piskunft.com</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Wallets</h4>
          <p onClick={() => handleCopyWallet('Creator wallet here')}>Creator</p>
          <p onClick={() => handleCopyWallet('Founder Wallet Here')}>Founder</p>
          <p onClick={() => handleCopyWallet('Artist Wallet here')}>Artist</p>
        </div>
      </div>
      <div className='gpt3__footer-copyright'>
        <p>Made by xoxer</p>
      </div>
    </div>
  )
}

export default Footer;
