import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
  {
    title: 'Backgrounds Attributes',
    text: 'There will be disparate types of backgrounds, having different and distinctive characteristics. Along with these features, the visual elements of the NFT will also change, causing them to develop into a well-defined rarity system.',
  },
  {
    title: 'Horns Attributes',
    text: 'Horns attributes encompass the unique aspects of the horns featured in each PisKu NFT. From their shape and size to their color and texture, horns add a distinctive flair to every digital creation. ',
  },
  {
    title: 'Faces Attributes',
    text: 'Faces attributes enclose the varied facial features and expressions depicted in each NFT. From the subtle nuances of emotion to the intricate details of facial structure, faces play a pivotal role in conveying the mood and personality of each digital artwork',
  },
  {
    title: 'Body colors Attributes',
    text: 'Body colors attributes refer to the diverse palette of colors adorning the bodies of our NFTs. From vibrant hues to subtle shades, each NFT boasts a unique combination of colors that define its visual identity. Whether its bold and striking or soft and pastel, the body colors attributes of our NFTs add depth, vibrancy, and personality to each digital masterpiece, ensuring that no two are alike.',
  }
]

const Features = () => {
  return (
    <div className='gpt3__features section__padding' id='features'>
      <div className='gpt3__features-heading'>
        <h1 className='gradient__text'>Discover the Diversity: Unveiling the Unique Attributes of Every PisKu NFT</h1>
        <p>Mint your PisKu on LaunchMyNFT</p>
      </div>
      <div className='gpt3__features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features