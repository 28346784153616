import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLin, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';
import buttonSound from '../../assets/Pisku.mp3'; // Importa il file audio
import './navbar.css';

const Menu = () => (
 <>
  <p><a href='#home'>Home</a></p>
  <p><a href='#wgpt3'>What is Pisku?</a></p>
  <p><a href='#features'>Attributes</a></p>
  <p><a href='#possibility'>Discord</a></p>
  <p><a href='#blog'>Marketplaces</a></p>
  <p><a href='#roadmap'>Roadmap</a></p>
 </>
)

const Navbar = () => {
 const [toggleMenu, setToggleMenu] = useState(false);
 const [buttonClicked, setButtonClicked] = useState(false);

 const playSound = () => {
    const sound = document.getElementById('buttonSound');
    sound.play();
    setButtonClicked(true); // Imposta lo stato del bottone come cliccato
    setTimeout(() => {
      setButtonClicked(false); // Reimposta lo stato del bottone dopo 200ms
    }, 200);
 };

  return (
    <div className='gpt3__navbar'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='gpt3__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
          <button 
            type='button' 
            onClick={playSound} 
            className={buttonClicked ? 'button-click-animation clicked' : 'button-click-animation'}
          >
            MINT NOW
          </button>
        </a>
        <audio id="buttonSound" src={buttonSound}></audio> {/* Utilizza il percorso importato */}
      </div>
      <div className='gpt3__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='gpt3__navbar-menu_container scale-up-center'>
            <div className='gpt3__navbar-menu_container-links'>
              <Menu />
              <div className='gpt3__navbar-menu_container-links-sign'>
                <a href='https://discord.gg/Xeqpn2fcZ7' target='_blank' rel='noopener noreferrer'>
                  <button type='button' onClick={playSound} className={buttonClicked ? 'button-click-animation clicked' : 'button-click-animation'}
                  >
                    MINT NOW
                  </button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar;
