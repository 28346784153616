import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>Join in the PisKu Family</h1>
        <p>Welcome, dear friends, to the world of PisKu, where magic and wonder await you! What are we? You may wonder, come, and see. Tiny creatures of whimsy and glee! PisKu NFTs, a delightful sight, each one a treasure, shining so bright. With hats so tall and smiles so wide, in our world, joy takes a cheerful ride!</p>
        <p>Collect us, trade us, make us your own, in this enchanted world, where dreams are sown. With every PisKu, a story unfolds, of laughter, of love, in colors untold. So join us now, come take a peek, in the land of PisKu, where curiosity links up seek. With every NFT, a piece of our heart, a magical journey, right from the start!</p>
  
        <div className='gpt3__header-content__people'>
          <img src={people} alt='people' />
          <p>600 discord community members</p>
        </div>
      </div>
      <div className='gpt3__header-image'>
        <img src={ai} alt='ai' />
      </div> 
    </div>
  );
};

export default Header;
